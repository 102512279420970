import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { useDrag } from "react-dnd";
import { QuestionTemplate } from "../../modals/modals";
import { Check, Close, Clear } from "@mui/icons-material";
import { Colors } from "../../theme/colors";
import { TextInput } from "../../ui/InputFields";
import { useAppDispatch } from "../../redux/hooks";
import { ReactComponent as DragIcon } from "../../images/icons/DragIcon.svg";
import { ReactComponent as EditIcon } from "../../images/icons/Editicon.svg";
import { ReactComponent as DeleteIcon } from "../../images/icons/bin.svg";
import {
  updateQuestionTemplates,
  deleteQuestionTemplateReducer,
  deleteQuestionTemplates,
  updateQuestionTemplateReducer,
} from "../../redux/questionTemplate/questiontemplate-slice";
import {
  deleteQuestionReducer,
  fetchTemplatesQuestionsById,
} from "../../redux/templates/templates-slice";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

interface QuestionTemplatesProps {
  item: QuestionTemplate;
  questionIndex: number;
  specificTemplateId?: number;
}

export const QuestionTemplates = ({
  item,
  questionIndex,
  specificTemplateId,
}: QuestionTemplatesProps) => {
  const dispatch = useAppDispatch();
  const [isEditable, setEditable] = useState<boolean>(false);
  const [editQuestionValue, setEditQuestionValue] = useState<string>("");
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "tableItem",
    item: { data: [item] },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    setEditQuestionValue(item.QuestionTemplate_Text || "");
  }, [item]);

  const submitUpdateQuestion = () => {
    const existingItem = Object.assign({}, item);
    if (existingItem.QuestionTemplate_Text != editQuestionValue) {
      existingItem.QuestionTemplate_Text = editQuestionValue;
      dispatch(updateQuestionTemplates(existingItem))
        .unwrap()
        .then((originalPromiseResult: any) => {
          dispatch(fetchTemplatesQuestionsById(existingItem.fk_Template_ID));
          dispatch(
            updateQuestionTemplateReducer({
              item: existingItem,
              index: questionIndex,
            })
          );
        })
        .catch((rejectedValueOrSerializedError: any) => {
          // handle create Campaigns error here
          console.log("getting error", rejectedValueOrSerializedError);
        });
    }
  };

  const deleteQuestionTemplate = (itemData: any) => {
    if (itemData?.pk_QuestionTemplate_ID) {
      dispatch(deleteQuestionTemplates(itemData.pk_QuestionTemplate_ID))
        .unwrap()
        .then((originalPromiseResult: any) => {
          dispatch(
            deleteQuestionReducer({
              item: itemData,
              index: questionIndex,
            })
          );
        })
        .catch((rejectedValueOrSerializedError: any) => {
          // handle create Campaigns error here
          console.log("getting error", rejectedValueOrSerializedError);
        });
    }
  };

  return (
    <div
      ref={drag}
      style={{
        borderRadius: "4px",
        border: `1px solid ${Colors.white}`,
        margin: "10px 0px",
      }}
    >
      <div
        style={{
          display: "flex",
          minHeight: 45,
          justifyContent: "space-between",
          alignItems: "center",
          background: Colors.white,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "calc(100% - 70px)",
          }}
        >
          {/* <DragIcon
            style={{ padding: "0px 8px", cursor: "move", minWidth: "19px" }}
        /> */}
          <DragIndicatorIcon
            style={{
              padding: "0px 8px",
              cursor: "move",
              minWidth: "19px",
              color: Colors.primary,
            }}
          />
          {isEditable ? (
            <TextInput
              value={editQuestionValue}
              onChange={(value) => {
                setEditQuestionValue(value);
              }}
              border={`1px solid ${Colors.default}`}
              placeholder={"Please fill in details"}
              width={90}
            />
          ) : (
            <Typography
              sx={{
                paddingTop: 0,
                color: Colors.primary,
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
              }}
              fontWeight={500}
              fontSize={"14px"}
            >
              {item.QuestionTemplate_Text}
            </Typography>
          )}
        </div>
        <div style={{ width: "70px" }}>
          {isEditable ? (
            <>
              <IconButton edge="end" aria-label="check">
                <Check
                  sx={{
                    color: Colors.primary,
                    fontSize: 25,
                  }}
                  onClick={() => {
                    setEditable(!isEditable);
                    submitUpdateQuestion();
                    // onEdit(question.id);
                  }}
                />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="check"
                onClick={() => {
                  setEditable(!isEditable);
                  setEditQuestionValue("");
                  // onEdit(question.id);
                }}
              >
                <Clear
                  sx={{
                    color: Colors.primary,
                    fontSize: 25,
                  }}
                />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => {
                  setEditQuestionValue(item.QuestionTemplate_Text || "");
                  setEditable(!isEditable);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  deleteQuestionTemplate(item);
                }}
              >
                <DeleteIcon style={{ marginLeft: "10px" }} />
              </IconButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
