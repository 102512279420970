import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { BackgroundColors, Colors, TextColors } from './colors';

export const ButtonThemeStyles: {
  styleOverrides: ComponentsOverrides['MuiButton'];
  variants: ComponentsVariants['MuiButton'];
} = {
  styleOverrides: {
    root: {
      borderRadius: 4,
      padding: '8px 16px',
      height: 40,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      textTransform: 'none',
      '&.MuiButton-sizeSmall': {
        fontSize: 14,
        padding: '4px 16px',
        height: 34,
      },
    },
  },
  variants: [
    {
      props: { variant: 'contained', color: 'primary' },
      style: {
        background: `linear-gradient(to right,  ${Colors.black},${Colors.black})`,
        color: TextColors.white,
        cursor: "pointer",
        borderRadius: "6px",
        fontWeight: 500,
        textTransform: 'none',
        width: "100%",
        '&:disabled': {
          backgroundColor: BackgroundColors.lightGrey,
          color: TextColors.default,
        },
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          background: `linear-gradient(to right,  ${Colors.black},${Colors.black})`,
        },
        '&:focus': {
          background: `linear-gradient(to right,  ${Colors.black},${Colors.black})`,
        },
        '&:active': {
          background: `linear-gradient(to right,  ${Colors.black},${Colors.black})`,
          color: TextColors.default,
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'secondary' },
      style: {
        color: Colors.dark_grey,
        border: '2px solid',
        borderColor: Colors.dark_grey,
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
          border: '2px solid',
        },
        '&:disabled': {
          color: TextColors.lightGrey,
        },
      },
    },
    {
      props: { variant: 'contained', color: 'secondary' },
      style: {
        textTransform: 'none',
        backgroundColor: BackgroundColors.white,
        color: TextColors.black,
        '&:hover': {
          backgroundColor: BackgroundColors.darkGrey,
        },
      },
    },
    {
      props: { variant: 'contained', color: 'inherit' },
      style: {
        textTransform: 'none',
        backgroundColor: BackgroundColors.lightGrey,
        color: TextColors.black,
        '&:hover': {
          backgroundColor: BackgroundColors.darkGrey,
        },
      },
    },
  ],
};
